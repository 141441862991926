<template>
  <div id="xunZhengDetail">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="xunZhengDetailCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="xunzhengData.title&&xunzhengData.title.length>0" class="animated bounceInLeft" v-html="xunzhengData.title[0].title"></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

      <!--      循证详细内容-->
      <div class="detailBox" >
        <div class="detailCon">
          <div v-show="hasData">
            <div class="topTitle">
              <span class="theMonthDay">{{dateInfo.theMonth}}月{{dateInfo.theDay}}日</span>
              <span class="theYear">{{dateInfo.theYear}}</span>
            </div>
            <div style="padding-top: 13.888vw;border-bottom: 0.2777vw solid #20BFD0;margin-bottom: 5.555vw;"></div>
            <div class="title">{{detailCon.title}}</div>
            <div class="text" v-html="detailCon.content" style="font-size: 3.333vw;line-height: 5.8333vw"></div>
          </div>
          <div style="color:#000;opacity: 0.8" v-show="!hasData">
            <div style="padding-top: 13.888vw;border-bottom: 0.2777vw solid #20BFD0;margin-bottom: 5.555vw;"></div>暂无数据</div>

        </div>

      </div>
          <div style="margin-top: 16.666vw;" v-show="hasData">
            <Copyright></Copyright>
          </div>
    </div>
    <div style="width: 100%;position: fixed;bottom: 0; left: 50%;transform: translateX(-50%)" v-show="!hasData">
      <Copyright></Copyright>
    </div>
  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetXunzhengDetail,GetIndexDoc} from "@/api/api";
export default {
  data(){
    return{
      //询证banner
      xunzhengData:{},
      //是否返回数据
      hasData:true,
      //当前列表
      detailCon: {},
      dateInfo: {}
    }
  },
  mounted() {
    const id=this.$route.query.id
    this.toGetXunzhengDetail(id)
    //获取询证解读页面banner数据
    this.toGetIndexDoc(7)
  },
  methods:{
    //获取询证解读页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.xunzhengData=[]
              return
            }
            let apiIndex=res.data
            _this.xunzhengData=apiIndex
          })
    },
    toGetXunzhengDetail(id){
      let _this=this
      GetXunzhengDetail(id)
          .then(r=>{
            let res=r.data

            if(res.status!=1){
              _this.hasData=false
              return
            }
            _this.hasData=true
            _this.detailCon=res.data
            _this.dateInfo.theMonth=res.data.date.slice(5,7)
            _this.dateInfo.theDay=res.data.date.slice(8,10)
            _this.dateInfo.theYear=res.data.date.slice(0,4)
          })
    },



  },
  components:{
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#xunZhengDetail{
  padding-top: 13.888vw;
  background-color: #071D42;
  min-height: calc(100vh - 13.888vw);
}
.xunZhengDetailCon{
  .commenHead{
    background:url("../assets/images/pic_banner_zhengxun.png") no-repeat 77% top;
    background-size: 156%;
  }
  //循证内容
  .detailBox{
    margin:0 6.666vw;

    .detailCon{
      margin-top:-5.555vw;
      padding: 0 4.444vw 5.555vw;
      background: #fff;
      border-radius: 1.1111vw;
      position: relative;
      .topTitle{
        padding:0 1.666vw;
        position: absolute;
        top: -1.388vw;
        height: 10vw;
        border-bottom-right-radius: 3.333vw;
        background: #20BFD0;
        span{
          line-height: 10vw;
          display: inline-block;
          font-size:3.888vw;
          color:#FAFBFD;
        }
        .theMonthDay{
          font-weight: 600;
        }
        .theYear{
          margin-left: 0.5555vw;
          font-weight: 400;
        }
      }
      .title{
        font-size: 4.444vw;
        line-height: 6.3888vw;
        color: #2B2B2B;
        font-weight:600;
        margin-bottom: 3vw;
      }
    }
  }

}
</style>
<style>
.xunZhengDetailCon .detailBox .detailCon .text img{
  width: calc(100vw - 22.222vw)!important;
  height: auto!important;
}
</style>